
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import UpdateQuestionnaire from "@/views/UpdateQuestionnaire.vue";
import { Question, QuestionnaireSection } from "@/shared/types/Questionnaire";
import { toCamelCase } from "@/utils/helpers";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import { getLocale } from "@/i18n";

@Options({
  components: {
    SectionTitle,
    UpdateQuestionnaire,
    LeftStepBar
  },
  created() {
    this.$store.dispatch("questionnaire/setQuestionnaire", this.$i18n.locale);
    this.selectLanguage(this.$route.params.lang);
  },
  mounted() {
    var params = new URLSearchParams();
    params.append("scope", "onboarding");
    params.append("user", "onboarding");
    params.append("pass", "6N9Xebc0i!Nn01%*G2d5");

    this.$store.dispatch("onboarding/setAuthToken", { params });
  },
  computed: {
    questionnaire(): Questionnaire {
      return this.$store.getters["questionnaire/getQuestionnaire"];
    },
    currentSection() {
      if (this.questionnaire) {
        return this.questionnaire.sections.find(
          (s: QuestionnaireSection) =>
            s.id === this.$store.getters["questionnaire/getCurrentSection"]
        );
      }

      return null;
    }
  },
  methods: {
    selectLanguage(language: string) {
      console.log("selectLanguage", language);
      const actualLocale = getLocale();
      localStorage.setItem("wem_locale", language);
      this.$i18n.locale = language;
      if (actualLocale != language) {
        location.reload();
      }
    },

    generateFormKeys() {
      return this.currentSection.questions.map((q: Question) =>
        toCamelCase(q.slug)
      );
    }
  }
})
export default class Questionnaire extends Vue {
}
