import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "md:flex justify-between" }
const _hoisted_2 = { class: "md:w-8/12 w-full relative background-cube inner-page pb-20" }
const _hoisted_3 = { class: "md:w-8/12" }
const _hoisted_4 = {
  key: 0,
  class: "md:w-6/12 md:pt-16 font-serif text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_LeftStepBar = _resolveComponent("LeftStepBar")!
  const _component_UpdateQuestionnaire = _resolveComponent("UpdateQuestionnaire")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SectionTitle, {
          class: "w-full",
          text: _ctx.$t('questionnaire.section-1.updateTitle')
        }, null, 8, ["text"]),
        _createVNode(_component_LeftStepBar, {
          title: 
            _ctx.$t('questionnaire.title') +
            (_ctx.currentSection.title ? ` - ${_ctx.currentSection.title}` : '')
          ,
          perex: _ctx.currentSection.perex,
          description1: _ctx.currentSection.description1
        }, null, 8, ["title", "perex", "description1"])
      ])
    ]),
    (_ctx.currentSection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_UpdateQuestionnaire, {
            section: _ctx.currentSection,
            formKeys: _ctx.generateFormKeys()
          }, null, 8, ["section", "formKeys"])
        ]))
      : _createCommentVNode("", true)
  ]))
}