
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import DocumentsUploadForm from "@/components/forms/DocumentsUploadForm.vue";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";

@Options({
  components: {
    SectionTitle,
    DocumentsUploadForm,
    LeftStepBar
  },
  data() {
    return {
      verificationResult: undefined
    };
  },
  async beforeMount() {
    try {
      this.$store.commit("onboarding/checkProfile");
      const uuid = this.$route.params.uuid;
      const token = localStorage.getItem("LeadToken");

      if (token) {
        const tokenUuid = JSON.parse(atob(token.split(".")[1]));
        console.log("Token UUID", tokenUuid.sub);
        console.log("UUID", uuid);
        if (uuid !== tokenUuid.sub) {
          this.$router.push({ name: "Login" });
        } else {
          this.verificationResult = await this.$store.dispatch(
            "onboarding/verificationResult"
          );
        }
      } else {
        console.log("Token not found");
      }
    } catch (error) {
      console.error('Error during beforeMount:', error);
    }
  },
  methods: {
    getTranslation(key: string) {
      try {
        const translation = this.$t(key);
        console.log(`Translation for ${key}:`, translation);
        return translation;
      } catch (error) {
        console.error('Translation error:', error);
        return key; // fallback to key if translation fails
      }
    },
    submitForm() {
      this.$store.dispatch("onboarding/startVerification");
    },
    continueOnboarding() {
      this.$router.push({ name: "DocumentsUpload" });
    }
  }
})
export default class DocumentsUpload extends Vue {}
