
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import InvestmentStrategyCard from "@/shared/components/InvestmentStrategyCard.vue";
import { mapGetters } from "vuex";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import { SelectedStrategy } from "@/shared/types/InvestmentStrategy";
import { showAlert } from "@/utils/helpers";

@Options({
  components: {
    SectionTitle,
    InvestmentStrategyCard,
    LeftStepBar
  },
  beforeMount() {
    // this.$store.commit("onboarding/checkProfile");
  },
  mounted() {
    this.$store.dispatch("onboarding/getStrategies");
    console.log(localStorage.getItem("wem_locale"));
  },
  computed: {
    strategies() {
      return this.$store.getters["onboarding/investmentStrategies"];
    },
    ...mapGetters({
      selectedStrategies: "onboarding/selectedStrategies",
      investorName: "questionnaire/getInvestorName"
    }),
    isLocaleEn() {
      return typeof localStorage !== 'undefined' && localStorage.getItem('wem_locale') === 'en';
    }
  },
  methods: {
    saveSelectedStrategies() {
      if (this.selectedStrategies.length > 0) {
        const invalidStrategies: any = [];

        this.selectedStrategies.forEach((strategy: SelectedStrategy) => {
          if (!this.isStrategyValid(strategy)) {
            invalidStrategies.push(strategy);
          }
        });

        if (invalidStrategies.length > 0) {
          showAlert(
            "error",
            this.$t("investment_strategy.invalid_strategies_error"),
            ""
          );
          return false;
        }
      }
      this.$store.commit("onboarding/saveSelectedStrategies");
    },
    isStrategyValid(strategy: SelectedStrategy) {
      return strategy.investment_monthly >= strategy.min_investment;
    }
  }
})
export default class AddNewInvestmentStrategy extends Vue {
}
