import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "md:flex justify-between" }
const _hoisted_2 = { class: "md:w-8/12 w-full relative background-cube inner-page pb-20" }
const _hoisted_3 = { class: "md:w-8/12" }
const _hoisted_4 = { class: "md:w-4/12 md:pt-36 font-serif text-sm" }
const _hoisted_5 = {
  key: 0,
  class: "my-10 font-sans"
}
const _hoisted_6 = {
  key: 0,
  class: "md:w-10/12 w-full mx-auto mt-5 md:mt-0"
}
const _hoisted_7 = {
  key: 0,
  class: "mb-16"
}
const _hoisted_8 = { class: "font-sans" }
const _hoisted_9 = {
  key: 0,
  class: "md:grid md:grid-cols-3 md:gap-5 mt-5"
}
const _hoisted_10 = {
  key: 1,
  class: "md:grid md:grid-cols-3 md:gap-5 mt-5"
}
const _hoisted_11 = {
  key: 1,
  class: "mb-10"
}
const _hoisted_12 = { class: "font-sans" }
const _hoisted_13 = {
  key: 0,
  class: "md:grid md:grid-cols-3 md:gap-5 mt-5"
}
const _hoisted_14 = {
  key: 1,
  class: "md:grid md:grid-cols-3 md:gap-5 mt-5"
}
const _hoisted_15 = { class: "md:flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_LeftStepBar = _resolveComponent("LeftStepBar")!
  const _component_InvestmentStrategyCard = _resolveComponent("InvestmentStrategyCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_SectionTitle, {
            class: "w-full",
            text: _ctx.$t('mainTitle')
          }, null, 8, ["text"]),
          _createVNode(_component_LeftStepBar, {
            title: _ctx.$t('investment_strategy.new_title'),
            perex: _ctx.$t('investment_strategy.perex'),
            description1: _ctx.$t('investment_strategy.description1')
          }, null, 8, ["title", "perex", "description1"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("investment_strategy.block")), 1),
        (_ctx.investorName)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.investorName), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("investment_strategy.block2")), 1)
      ])
    ]),
    (_ctx.strategies)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_ctx.strategies.pravidelne_strategie.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.$t("investment_strategy.regular_heading")), 1),
                (_ctx.isLocaleEn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies.pravidelne_strategie, (strategy) => {
                        return (_openBlock(), _createBlock(_component_InvestmentStrategyCard, {
                          cardTitle: strategy.name_en,
                          hasInvestmentFrequency: true,
                          amountLabel: _ctx.$t('investment_strategy.regular_label'),
                          strategy: strategy,
                          key: strategy.id
                        }, null, 8, ["cardTitle", "amountLabel", "strategy"]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies.pravidelne_strategie, (strategy) => {
                        return (_openBlock(), _createBlock(_component_InvestmentStrategyCard, {
                          cardTitle: strategy.name,
                          hasInvestmentFrequency: true,
                          amountLabel: _ctx.$t('investment_strategy.regular_label'),
                          strategy: strategy,
                          key: strategy.id
                        }, null, 8, ["cardTitle", "amountLabel", "strategy"]))
                      }), 128))
                    ]))
              ]))
            : _createCommentVNode("", true),
          (_ctx.strategies.jednorazove_strategie.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.$t("investment_strategy.onetime_heading")), 1),
                (_ctx.isLocaleEn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies.jednorazove_strategie, (strategy) => {
                        return (_openBlock(), _createBlock(_component_InvestmentStrategyCard, {
                          cardTitle: strategy.name_en,
                          amountLabel: _ctx.$t('investment_strategy.onetime_label'),
                          strategy: strategy,
                          key: strategy.id
                        }, null, 8, ["cardTitle", "amountLabel", "strategy"]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.strategies.jednorazove_strategie, (strategy) => {
                        return (_openBlock(), _createBlock(_component_InvestmentStrategyCard, {
                          cardTitle: strategy.name,
                          amountLabel: _ctx.$t('investment_strategy.onetime_label'),
                          strategy: strategy,
                          key: strategy.id
                        }, null, 8, ["cardTitle", "amountLabel", "strategy"]))
                      }), 128))
                    ]))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.saveSelectedStrategies && _ctx.saveSelectedStrategies(...args))),
              class: "mb-5 md:w-1/3 w-full btn secondary font-sans"
            }, _toDisplayString(_ctx.$t("investment_strategy.button")), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}