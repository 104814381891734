<template>
  <div>
    <p class="w-full text-secondary mt-2" style="font-size: 13.6px">
      {{ label }}
    </p>
    <div v-for="(option, index) in options" :key="index" style="display: flex">
      <input
        type="radio"
        :id="option.name"
        :value="option.value"
        v-model="selectedOption"
      />
      <label :for="index" class="square-radio"></label>
      <span class="radio-label" style="font-size: 13.6px">{{ option.name }}</span>
    </div>
    <button @click="submit">Submit</button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: this.value, // Initialize the selectedOption with the value prop
    };
  },
  watch: {
    selectedOption(newValue) {
      // Emit the updated selectedOption whenever it changes
      this.$emit("input", newValue);
    },
  },
  methods: {
    submit() {
      // Collect and emit all selected answers at the end
      this.$emit("submit", this.selectedOption);
    },
  },
};
</script>

<style scoped>
/* Your custom CSS for the radio group if needed */
</style>
