<template>
  <div>
    <p class="w-full text-secondary mt-2" style="font-size: 13.6px">
      {{ label }}
    </p>
    <!-- Your custom checkbox group implementation here -->
    <!-- For example, you can use the v-for loop to render the options -->
    <div v-for="(option, index) in options" :key="index" style="display: flex">
      <input
        type="checkbox"
        :id="option.name"
        :value="option.value"
        v-model="selectedOptions"
      />
      <label :for="index" class="square-radio"></label>
      <span class="radio-label" style="font-size: 13.6px">{{ option.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedOptions: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped>
/* Your custom CSS for the checkbox group if needed */
</style>
