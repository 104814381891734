import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "md:flex justify-between" }
const _hoisted_2 = { class: "md:w-8/12 w-full relative background-cube inner-page pb-20" }
const _hoisted_3 = { class: "md:w-8/12" }
const _hoisted_4 = {
  class: "md:w-4/12 md:pt-10",
  style: {"margin-top":"110px"}
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_LeftStepBar = _resolveComponent("LeftStepBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SectionTitle, {
          class: "w-full",
          text: _ctx.$t('mainTitle')
        }, null, 8, ["text"]),
        _createVNode(_component_LeftStepBar, {
          step: 6,
          title: _ctx.$t('verification.start-title'),
          perex: _ctx.$t('verification.perex')
        }, null, 8, ["title", "perex"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", { innerHTML: _ctx.formattedPerex }, null, 8, _hoisted_5),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
        class: "mt-5 mb-12 w-full btn secondary flex-1"
      }, _toDisplayString(_ctx.$t("verification.verify")), 1)
    ])
  ]))
}