
import { Options, Vue } from "vue-class-component";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";

@Options({
  components: {
    LeftStepBar,
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  data() {
    return {
      clientzoneUrl: process.env.VUE_APP_CLIENTZONE_URL,
      enClientzoneUrl: process.env.VUE_APP_EN_CLIENTZONE_URL,
    };
  },
})
export default class ThankYou extends Vue {}
