
import { Options, Vue } from "vue-class-component";
import FormRadioGroup from "@/shared/components/FormRadioGroup.vue";
import FormMultipleChoiceGroup from "@/shared/components/FormMultipleChoiceGroup.vue";
import CustomRadioGroup from "@/shared/components/CustomRadioGroup.vue";
import CustomCheckboxGroup from "@/shared/components/CustomCheckboxGroup.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@/utils/i18n-validators";
import { Answer, Question, SelectedAnswer } from "@/shared/types/Questionnaire";
import { toCamelCase } from "@/utils/helpers";
import { mapGetters } from "vuex";

@Options({
  data() {
    return {
      answers: "",
      v$: useVuelidate(),
      formSubmitted: false,
      validationErrors: [],
      form: {},
      selectedAnswers: {}
    };
  },
  props: {
    section: {
      required: true
    },

    formKeys: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      riskProfile: "questionnaire/getRiskProfile",
      // riskProfiles: "questionnaire/getRiskProfiles",
      investorName: "questionnaire/getInvestorName"
    }),
    validators() {
      return this.section.questions.reduce((map: any, q: Question) => {
        map[toCamelCase(q.slug)] = { required, $lazy: true };
        return map;
      }, {});
    },
    canShowQuestion() {
      return (question: Question) => {
        if (!question.where) {
          return true;
        }

        for (const key in question.where) {
          if (Object.prototype.hasOwnProperty.call(question.where, key)) {
            const value = question.where[key];
            let formValue = this.form[toCamelCase(key)];
            if (typeof formValue === "object") {
              for (let index = 0; index < formValue.length; index++) {
                const answer: SelectedAnswer = formValue[index];

                if (answer.answer != value) {
                  return false;
                }
              }
            } else {
              if (formValue != value) {
                return false;
              }
            }
          }
        }

        return true;
      };
    }
  },
  components: {
    FormRadioGroup,
    FormMultipleChoiceGroup,
    CustomRadioGroup,
    CustomCheckboxGroup
  },
  mounted() {
    this.getTokenFromUrl();
    var params = new URLSearchParams();
    params.append("scope", "onboarding");
    params.append("user", "onboarding");
    params.append("pass", "6N9Xebc0i!Nn01%*G2d5");
    this.$store.dispatch("onboarding/getLastQuestionaire");

    this.$store.dispatch("onboarding/setAuthToken", { params });
  },
  methods: {

    doesQuestionExist(questionId: any) {
      const apiQuestions = this.$store.state.onboarding.lastQuestionaire;
      return apiQuestions.some((question: any) => question.question === questionId);
    },

    isQuestionMatched() {
      const apiQuestions = this.$store.state.onboarding.lastQuestionaire;
      let isMatched = false;

      this.section.questions.forEach((question: Question) => {
        const questionSlug = this.getKey(question);
        const editedQuestionSlug = this.getKey2(question);

        const matchedResponses = apiQuestions.filter((apiQuestion: any) => {
          const editedApiQuestion = apiQuestion.question.replace(/step\d+_/g, "");
          return editedApiQuestion === editedQuestionSlug;
        });

        if (matchedResponses.length > 0) {
          isMatched = true;

          const key = this.getKey(question);

          // Handle regular non-multiple questions
          if (!question.multiple) {
            const matchedResponse = matchedResponses[0];
            this.form[`${key}_remote_selected`] = matchedResponse.answer;
            if (matchedResponse.text) {
              this.form[`${key}_remote_text`] = matchedResponse.text;
            }
          }
          // Handle multiple questions
          else {
            // Initialize arrays to hold multiple values
            this.form[`${key}_multiple_selected`] = [];
            this.form[`${key}_multiple_text`] = ''; // Initialize as an empty string
            this.form[`${key}_multiple_points`] = [];

            matchedResponses.forEach((matchedResponse: any) => {
              this.form[`${key}_multiple_selected`].push(matchedResponse.answer);
              if (matchedResponse.text) {
                this.form[`${key}_multiple_text`] = matchedResponse.text;
              }
              // Always push the points into the array, regardless of their value
              this.form[`${key}_multiple_points`].push(matchedResponse.points || 0);
            });
          }
        } else {
          const key = this.getKey(question);
          // this.form[`${key}_remote_selected`] = undefined; // Set remote-selected to undefined for unmatched questions
        }
      });

      return isMatched;
    },

    getKey2(question: Question): string {
      const slug = question.slug.toLowerCase().replace(/\s+/g, "_");
      const words = slug.split("_");
      console.log("Words:", words);
      const formattedWords = words.map((word, index) => {
        if (index === 0) {
          return word;
        } else {
          return word.charAt(0).toLowerCase() + word.slice(1);
        }
      });
      const formattedSlug = formattedWords.join("_");
      // console.log("Formatted Slug:", formattedSlug);
      return formattedSlug;
    },

    getRemoteSelected(question: Question): string | undefined {
      const key = this.getKey(question);
      return this.form[`${key}_remote_selected`];
    },

    getKey(question: Question) {
      console.log("Question Slug:", question.slug);
      return toCamelCase(question.slug);
    },

    extractKeyFromQuestion(str: string): string {
      const splitStr = str.split("_");
      return splitStr.slice(1).join("_");
    },


    // getKey(question: Question) {
    //   return toCamelCase(question.slug);
    // },
    getLabel(question: Question) {
      if (typeof question.order == "string") {
        return `${question.order} ${question.question}`;
      }

      return `${question.order}. ${question.question}`;
    },
    getQuestionArray(question: Question) {
      if (question.sub) {
        return question.questions;
      }

      return [question];
    },
    getTokenFromUrl() {
      const token = window.location.hash.split("/")[3];
      // console.log(token);
      this.$store.commit("questionnaire/setToken", token);
    },
    nextSection() {
      this.formSubmitted = true;
      const validationErrors: any[] = [];
      this.v$.$touch();

      this.formKeys.forEach((key: string, i: number) => {
        if (
          (!this.form[key] && !this.section.questions[i].notRequired) ||
          (this.form[key] && !this.form[key].length)
        ) {
          validationErrors.push({
            key,
            error: this.v$.form[key].$errors[0]
          });
        }
      });

      this.validationErrors = validationErrors;
      // console.log(this.formKeys);
      // console.log(this.form);
      // console.log(validationErrors);
      if (!Object.keys(this.validationErrors).length) {
        const answers: SelectedAnswer[] = [];

        this.formKeys.forEach((keyMain: string, i: number) => {
          let questions = [this.section.questions[i]];
          if (this.section.questions[i].sub) {
            questions = this.section.questions[i].questions;
          }

          questions.forEach((question: Question) => {
            let key = toCamelCase(question.slug);

            if (typeof this.form[key] !== "undefined") {
              if (typeof this.form[key] === "object") {
                this.form[key].forEach((answer: SelectedAnswer) => {
                  answers.push({
                    ...answer,
                    question: `step${this.section.id}_${question.slug}`
                  });
                });
              } else {
                var points = 0;
                if (question.answers) {
                  let answer = question.answers.find(
                    (answer: Answer) => answer.value === this.form[key]
                  );

                  if (answer && answer.points) {
                    points = answer.points;
                  }
                }
                answers.push({
                  question: `step${this.section.id}_${question.slug}`,
                  answer: this.form[key],
                  points: points,
                  text: ""
                });
              }
            }
          });
        });
        this.$store.dispatch("questionnaire/updateNextSection", answers);
        this.$store.dispatch("questionnaire/getRiskProfiles", this.riskProfile);
        window.scrollTo(0, 0);
      }
    },
    questionHasError(key: string) {
      const error = this.validationErrors.find((e: any) => e.key === key);
      if (error) {
        return true;
      }
      return false;
    },
    getQuestionError(key: string) {
      const error = this.validationErrors.find((e: any) => e.key === key);
      if (error) {
        return error.error;
      }
      return false;
    }
  },
  watch: {
    formKeys() {
      this.form = this.formKeys.reduce((map: any, key: string) => {
        map[key] = "";
        return map;
      }, {});
    }
  },

  validations() {
    return {
      form: this.validators
    };
  }
})
export default class UpdateQuestionnaire extends Vue {
}
