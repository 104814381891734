import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "md:flex justify-between" }
const _hoisted_2 = { class: "md:w-8/12 w-full relative background-cube inner-page pb-20" }
const _hoisted_3 = { class: "md:w-8/12" }
const _hoisted_4 = {
  class: "md:w-4/12 md:pt-10",
  style: {"margin-top":"110px"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_LeftStepBar = _resolveComponent("LeftStepBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SectionTitle, {
          class: "w-full",
          text: _ctx.getTranslation('mainTitle')
        }, null, 8, ["text"]),
        _createVNode(_component_LeftStepBar, {
          step: 7,
          title: _ctx.getTranslation('verification.status'),
          perex: _ctx.getTranslation('verification.success-perex')
        }, null, 8, ["title", "perex"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.verificationResult === 'canceled')
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.getTranslation("verification.error_description")), 1))
        : _createCommentVNode("", true),
      (_ctx.verificationResult === 'declined')
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.getTranslation("verification.declined")), 1))
        : _createCommentVNode("", true),
      (_ctx.verificationResult === 'approved')
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.getTranslation("verification.continue_description")), 1))
        : _createCommentVNode("", true),
      (_ctx.verificationResult === 'submitted')
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.getTranslation("verification.submitted")), 1))
        : _createCommentVNode("", true),
      (_ctx.verificationResult === 'canceled')
        ? (_openBlock(), _createElementBlock("button", {
            key: 4,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
            class: "mt-5 mb-12 w-full btn secondary flex-1"
          }, _toDisplayString(_ctx.getTranslation("verification.repeat")), 1))
        : _createCommentVNode("", true),
      (_ctx.verificationResult === 'approved')
        ? (_openBlock(), _createElementBlock("button", {
            key: 5,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.continueOnboarding && _ctx.continueOnboarding(...args))),
            class: "mt-5 mb-12 w-full btn secondary flex-1"
          }, _toDisplayString(_ctx.getTranslation("verification.continue")), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}